import React from 'react';

import Layout from '../components/layout';
import Title from '../components/page-title';
import * as styles from './publications.module.scss';

// import ComingSoon from "../components/coming-soon"

export default function () {
  return (
    <Layout
      noticeText="View book stores in Nigeria"
      noticeLink="https://docs.google.com/spreadsheets/d/1QJ1ce9KxEjRmHNRE0ufzQhA8zPlVUlLd/edit?usp=sharing&ouid=110588503441816651200&rtpof=true&sd=true"
      noticeLinkText="Here"
    >
      <Title>Publications</Title>
      <div className={styles.publications}>
        <div className={styles.book}>
          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237816/ACAIN/Bookpeddler-books-Whose-favorite-am-I_-300x417_wmqtpd.jpg"
                alt="Whose Favourite am I?"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Whose Favourite am I?</div>
            <span className={styles.book__subtitle}>Chigozie A. Mbadugha</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237854/ACAIN/81QrrGpcyiL_t5cvgz.jpg"
                alt="A Visit to Grandad: An African"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              A Visit to Grandad: An African ABC
            </div>
            <span className={styles.book__subtitle}>
              Sade Fadipe &amp; Shedrach Ayalomeh
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237819/ACAIN/IMG-20220209-WA0001_ud0eur.jpg"
                alt="I am a Nigerian"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>I am a Nigerian (series)</div>
            <span className={styles.book__subtitle}>Foluke Oyeleye</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/Screenshot_20220324-195638_Drive_d2ygjd.jpg"
                alt="The Greedy Ostrich"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>The Greedy Ostrich</div>
            <span className={styles.book__subtitle}>Olusayo Ajetunmobi</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220112-WA0014-1_kln4na.jpg"
                alt="Ebun the Exotic Elephant"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Ebun the Exotic Elephant</div>
            <span className={styles.book__subtitle}>
              Oyinda Kazeem &amp; Olamide Fayemi
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220203-WA0007_v3gmij.jpg"
                alt="Mma Made"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Mma Made</div>
            <span className={styles.book__subtitle}>
              Chinyere Evelyn Ifediora
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/IMG-20211119-WA0005-1_r20pdd.jpg"
                alt="Pamima's Notebook"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Pamima's Notebook</div>
            <span className={styles.book__subtitle}>Diseph Ruth Otto</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/doing-right-cover-design_by_Olayemi_Ejemai-1_bl1fvd.jpg"
                alt="Doing Right: One good turn deserves another"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              Doing Right: One good turn deserves another
            </div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1652063317/ACAIN/Abdulkareem_Baba_Aminu_lhieqx.jpg"
                alt="The Most Important Comic Book on Earth"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              The Most Important Comic Book on Earth
            </div>
            <span className={styles.book__subtitle}>
              Abdulkareem Baba-Aminu
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237853/ACAIN/a_visit_to_grandma_and_grandpa_by_Chigozie_Mbadugha_-_Copy-1_mjghzf.jpg"
                alt="A Visit to Grandma and Grandpa"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              A Visit to Grandma and Grandpa
            </div>
            <span className={styles.book__subtitle}>Chigozie A. Mbadugha</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220203-WA0005-1_hvmsv4.jpg"
                alt="Adventures of Mister Sweet Patato"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              Adventures of Mister Sweet Patato
            </div>
            <span className={styles.book__subtitle}>
              Chinyere Evelyn Ifediora
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220203-WA0006_t87j74.jpg"
                alt="I can smell Christmas"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>I can smell Christmas</div>
            <span className={styles.book__subtitle}>
              Chinyere Evelyn Ifediora
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/I_love_my_Body_by_Olayemi_Ejemai-1_igbtki.jpg"
                alt="I Love my Body"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>I Love my Body</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220203-WA0004_e6c5x2.jpg"
                alt="All for Jan the Dog"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>All for Jan the Dog</div>
            <span className={styles.book__subtitle}>
              Chinyere Evelyn Ifediora
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/Bookpeddler-My-piggy-bank_by_Olayemi_Ejemai_cbttbf.jpg"
                alt="My Piggy Bank"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>My Piggy Bank</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/Bookpeddler-my-dad-is-a-super-hero_by_Olayemi_Ejemai_mlg3oa.jpg"
                alt="My Dad is a Super Hero"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>My Dad is a Super Hero</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237816/ACAIN/Bookpeddler-God-made-us-all_by_Olayemi_Ejemai_czqang.jpg"
                alt="God Made Us All"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              God Made Us All (A picture book for children about Love and
              Acceptance)
            </div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/Bookpeddler-the-big-bright-light-300x417_by_Olayemi_Ejemai_kgg3d3.jpg"
                alt="The Big Bright Light"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>The Big Bright Light</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237816/ACAIN/Bookpeddler-my-mum-my-pride-300x417_by_Olayemi_Ejemai_c6o6o1.jpg"
                alt="My Mum My Pride"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>My Mum My Pride</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237816/ACAIN/Bookpeddler-my-best-pet_by_Olayemi_Ejemai_uyfhsd.jpg"
                alt="My Best pet"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>My Best pet</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237816/ACAIN/Bookpeddler-in-the-right-place_by_Olayemi_Ejemai_c15kdc.jpg"
                alt="In The Right Place"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>In The Right Place</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237815/ACAIN/Bookpeddler-books-Kitty-stop-being-afraid-300x417_-_Copy_fmlhj2.jpg"
                alt="Kitty Stops Being Afraid"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Kitty Stops Being Afraid</div>
            <span className={styles.book__subtitle}>Chigozie A. Mbadugha</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237815/ACAIN/29070650._UX500_SS500__nwdrf4.jpg"
                alt="A Fun ABC"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>A Fun ABC</div>
            <span className={styles.book__subtitle}>
              Sade Fadipe &amp; Shedrach Ayalomeh
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237815/ACAIN/51yd0mvxDZL._SX258_BO1_204_203_200_-1_qcxyse.jpg"
                alt="Snow Joy"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Snow Joy</div>
            <span className={styles.book__subtitle}>Sade Fadipe</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237815/ACAIN/a-little-of-everything-cover-design_0_by_Olayemi_Ejemai_ex5ple.jpg"
                alt="A Little of Everything"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>A Little of Everything</div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/Diary-of-a-Toddler-book_w8tilp.jpg"
                alt="Dairy of a toddler"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Dairy of a Toddler</div>
            <span className={styles.book__subtitle}>
              Olubunmi Aboderin Talabi
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236508/ACAIN/book1_hjajg7.png"
                alt="Power Cut"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Power Cut</div>
            <span className={styles.book__subtitle}>Tonye Faloughi-Ekezie</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/cover-1_vwqnnm.png"
                alt="Dragons can sing"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Dragons can sing</div>
            <span className={styles.book__subtitle}>Basirat Razaq-Shuaib</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236796/ACAIN/tobi-bakes-cake-cover_bsp1s8.jpg"
                alt="Tobi Bakes Cake"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Tobi Bakes Cake</div>
            <span className={styles.book__subtitle}>
              Olubunmi Aboderin Talabi
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/I_am_not_naughty_olg5mr.jpg"
                alt="I Am Not Naughty I Really Mean It!"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              I Am Not Naughty I Really Mean It!
            </div>
            <span className={styles.book__subtitle}>Basirat Razaq-Shuaib</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236508/ACAIN/book2_lh7nbi.png"
                alt="Fruits and Vegetables"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Fruits and Vegetables</div>
            <span className={styles.book__subtitle}>Tonye Faloughi-Ekezie</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236508/ACAIN/book3_hjfrvz.png"
                alt="What is Down Syndrome?"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>What is Down Syndrome?</div>
            <span className={styles.book__subtitle}>Tonye Faloughi-Ekezie</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236556/ACAIN/tlts-cover_bw38h9.jpg"
                alt="Tobi Learns to Swim"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Tobi Learns to Swim</div>
            <span className={styles.book__subtitle}>
              Olubunmi Aboderin Talabi
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236508/ACAIN/book4_gphvtm.png"
                alt="I can Do It Myself"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>I can Do It Myself</div>
            <span className={styles.book__subtitle}>Tonye Faloughi-Ekezie</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236508/ACAIN/Big-school_f99ops.jpg"
                alt="Fisrt Day at the Big School"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              Fisrt Day at the Big School
            </div>
            <span className={styles.book__subtitle}>Chioma Momah</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/Museum_i9plsi.jpg"
                alt="First Day at the Big Museum"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Fun Day at the Museum</div>
            <span className={styles.book__subtitle}>Chioma Momah</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/Iyaji-the-housegirl_rsftbb.jpg"
                alt="Iyaji the Housegirl"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Iyaji the Housegirl</div>
            <span className={styles.book__subtitle}>Lola Shoneyin</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/Mayowa-and-the-Masqurade_ippl5m.jpg"
                alt="Mayowa and the Masqurade"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Mayowa and the Masqurade</div>
            <span className={styles.book__subtitle}>Lola Shoneyin</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649236555/ACAIN/Do-as-your-are-told_haskld.jpg"
                alt="Do as your are told"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Do as you are told Baji!</div>
            <span className={styles.book__subtitle}>Lola Shoneyin</span>
          </div>
        </div>
      </div>
    </Layout>
  );
}
